@use './design/colors';
@use './design/fonts';
@use './design/spacing';

@import './design/cssreset';

@import './design/components/homepage.scss';
@import './design/components/composition.scss';
@import './design/components/work.scss';

body {
  z-index: 0;
  font-family: 'Yantramanav', sans-serif;
  background-color: colors.$black;
  color: colors.$white;
  display: flex;
  justify-content: space-around;
  overflow-x: hidden;
}

.App {
  width: 100vw;
  overflow-x: hidden;
}

a {
  color: colors.$blue;
  text-decoration: none;
}

.banner-title {
  padding-top: spacing.$space-5;
  font-size: fonts.$huge;
  text-align: center;
  color: colors.$red;
}

.banner-subtitle {
  font-size: fonts.$larger;
  text-align: center;
}

.banner-links {
  margin-top: spacing.$space-4;
  font-size: fonts.$normal-plus;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pages {
  transition: left 0.5s;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  display: flex;
  flex-direction: row;

  position: relative;

  &[data-active-index="-1"] {
    .music-page {
      left: 0;
      
      .return-home {
        opacity: 1;
      }
    }

    .home-page {
      position: fixed;
      left: 100vw;
    }
    
    .development-page {
      position: fixed;
      left: 200vw;
    }
  }

  &[data-active-index="0"] {
    .music-page {
      position: fixed;
      left: -100vw;
    }

    .home-page {
      left: 0;
    }
    
    .development-page {
      position: fixed;
      left: 100vw;
    }
  }

  &[data-active-index="1"] {
    .music-page {
      position: fixed;
      left: -200vw;
    }
    
    .home-page {
      position: fixed;
      left: -100vw;
    }

    .development-page {
      left: 0;
      
      .return-home {
        opacity: 1;
      }
    }
  }
}

.page {
  transition: left 0.5s;
  position: relative;
  display: flex;
  width: 100vw;
  max-width: 100vw;
  padding: 0 max(calc((100vw - 1000px) / 2), #{spacing.$space-4});

  &.music-page {
    left: -100vw;
  }

  &.home-page {
    left: 0;
  }

  &.development-page {
    left: 100vw;
  }

  .page-header {
    color: colors.$red;
    font-size: fonts.$larger;
    padding: spacing.$space-5 0 spacing.$space-3; 
  }

  .page-content {
    flex: 1 1 auto;
  }

  .page-preface {
    font-size: fonts.$normal-plus;
    border-bottom: colors.$white-border;
    padding-bottom: spacing.$space-3;
  }

  p {
    padding: spacing.$space-3 0;
  }
}

.return-home {
  transition: opacity 0.5s;
  opacity: 0;

  // Hide things below
  background: colors.$black;
  z-index: 3;

  position: fixed;
  border-top: none;
  top: 0vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: spacing.$space-4;
  font-size: fonts.$larger;
}

.development-page .return-home {
  border-left: none;
  left: 0;

  .fa {
    position: relative;
    left: -3px;
  }
}

.music-page .return-home {
  border-right: none;
  right: 0;

  .fa {
    position: relative;
    left: 3px;
  }
}
