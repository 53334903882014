/*
 * This is based on the "a11y-css-reset" from https://github.com/mike-engel/a11y-css-reset.
 */

 html,
 body {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     min-height: 100%;
     width: 100%;
 }
 
 *, *::before, *::after {
     box-sizing: inherit;
 }
 
 p {
     margin: 0;
 }
 
 *:focus:not(:focus-visible),
 *::before:focus:not(:focus-visible),
 *::after:focus:not(:focus-visible) {
     outline: none;
 }
 
 /* The idea of putting this rule in the "reset" file is that browsers should probably set it this
    way to begin with. */
 @media(prefers-reduced-motion: reduce) {
     *, *::before, *::after {
         transition: none !important;
         animation: none !important;
         scroll-behavior: auto !important;
     }
 }
 
 
 ol, ul, li {
     list-style: none;
     margin: 0;
     padding: 0;
 }
 
 /* Re-enable list semantics for Safari with VoiceOver. See blog post for further explanation.
    https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */
 ol, ul {
     list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
 }
 
 /* This is part of the EGRET DS-specific reset. That is: it's not necessary for accessibility,
    but the rest of the CSS here relies on these properties being effectively "zeroed." */
 h1, h2, h3, h4, h5, h6 {
     font-size: 100%;
     font-weight: normal;
     margin: 0;
     padding: 0;
 }