$huge: 4em;
$larger: 3em;
$large: 2em;
$normal-plus: 1.3em;
$normal: 1em;

// Scale down everything at 800px
@media (max-width: 800px) {
  body {
    font-size: 12px;
  }
}